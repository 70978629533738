<template>
  <div>
    <tab3 @openModalAlertText="openModalAlertText" />
    <ModalAlertText :textModal="textModal" :arrModal="arrStatus" />
  </div>
</template>

<script>
import Tab3 from "../../../../eventsetting/eventsettingdetail/component/tap3.vue";
import ModalAlertText from "@/components/modal/ModalAlertText.vue";
export default {
  name: "ServiceBranchSchuleTab",

  components: { Tab3, ModalAlertText },
  data() {
    return { textModal: "", arrStatus: [] };
  },
  mounted() {},

  methods: {
    openModalAlertText(val) {
      this.textModal = val;
      this.$bvModal.show("modal-alert-text");
    },
  },
};
</script>

<style lang="scss" scoped>
.tap3 {
  background: white;
  padding: 15px;
  min-height: 60vh;
  .title {
    font-size: 18px;

    .icon {
      cursor: pointer;
      font-size: 14px;
    }
  }

  .sec1 {
    margin-top: 10px;
  }
}
</style>
