<template>
  <div>
    <div class="header-eventgroup">
      <h1 class="tw-p-4">Service Branch Setting</h1>

      <b-dropdown
        v-if="language"
        :text="language.name"
        class="tw-h-10 tw-m-4 tw-min-w-10"
      >
        <b-dropdown-item
          v-for="(item, index) in listLang"
          :key="index"
          @click="changeLanguage(item, index)"
        >
          <div :class="language.languageId === item.languageId && `item`">
            {{ item.name }}
          </div>
        </b-dropdown-item>
      </b-dropdown>
    </div>
    <div class="tw-bg-white tw-min-h-screen">
      <b-tabs
        content-class="mt-3"
        active-nav-item-class="font-weight-bold "
        justified
        v-model="tabIndex"
      >
        <div v-if="!loading">
          <b-tab>
            <template #title>
              <div class="tw-flex tw-items-center tw-w-full tw-justify-center">
                Detail
              </div>
            </template>
            <div v-if="tabIndex === 0">
              <detail-tab
                :loading="loading"
                :form="form"
                :language="language"
                :isCurrenTab="tabIndex === 0"
                :v="$v"
                @openModalAlertText="openModalAlertText"
              />
            </div>
          </b-tab>
          <b-tab>
            <template #title>
              <div class="tw-flex tw-items-center tw-w-full tw-justify-center">
                <b-icon
                  v-show="errTab[1].error"
                  class="icon-error tw-mr-2 tw-text-red-500"
                  icon="exclamation-circle"
                ></b-icon>
                Ticket/Zone
              </div>
            </template>
            <div v-if="tabIndex === 1">
              <ticket-zone-tab
                :previewOrPublish="form.event.previewOrPublish"
                :isLockProduct="form.event.isLockProduct"
                @openModalAlertText="openModalAlertText"
              /></div
          ></b-tab>
          <b-tab>
            <template #title>
              <div class="tw-flex tw-items-center tw-w-full tw-justify-center">
                <b-icon
                  v-show="errTab[2].error"
                  class="icon-error tw-mr-2 tw-text-red-500"
                  icon="exclamation-circle"
                ></b-icon>

                Schedule
              </div>
            </template>
            <div v-if="tabIndex === 2"><schedule-tab /></div
          ></b-tab>
          <b-tab>
            <template #title>
              <div class="tw-flex tw-items-center tw-w-full tw-justify-center">
                <b-icon
                  v-show="errTab[3].error"
                  class="icon-error tw-mr-2 tw-text-red-500"
                  icon="exclamation-circle"
                ></b-icon>
                Customer
              </div>
            </template>
            <div v-if="tabIndex === 3"><customer-tab /></div
          ></b-tab>
          <b-tab>
            <template #title>
              <div class="tw-flex tw-items-center tw-w-full tw-justify-center">
                <b-icon
                  v-show="errTab[4].error"
                  class="icon-error tw-mr-2 tw-text-red-500"
                  icon="exclamation-circle"
                ></b-icon>
                Banner
              </div>
            </template>
            <div v-if="tabIndex === 4">
              <banner-tab
                :data="form"
                @openModalAlertText="openModalAlertText"
              /></div
          ></b-tab>
          <b-tab>
            <template #title>
              <div class="tw-flex tw-items-center tw-w-full tw-justify-center">
                <b-icon
                  v-show="errTab[5].error"
                  class="icon-error tw-mr-2 tw-text-red-500"
                  icon="exclamation-circle"
                ></b-icon>
                Additional Data
              </div>
            </template>
            <div v-if="tabIndex === 5">
              <additional-data-tab
                :selectLang="language"
                :data="form"
                :loading="loading"
                :v="$v"
                :indexLanguage="indexLanguage"
              /></div
          ></b-tab>
        </div>
        <div class="tw-flex tw-justify-center tw-items-center tw-h-full" v-else>
          <b-spinner class="align-middle"></b-spinner>
          <strong class="ml-2">Loading... </strong>
        </div>
      </b-tabs>
    </div>
    <div
      class="tw-sticky tw-bottom-0 tw-bg-white tw-z-40 tw-w-full tw-h-16 tw-px-3"
    >
      <div class="footer-event-setting">
        <b-button class="btn-filter cancel" @click="handleBack">
          Cancel
        </b-button>
        <b-button
          class="btn-filter save"
          :disabled="loading"
          @click="onSaveEvent"
        >
          Save
          <b-spinner
            class="m-0 ml-1"
            label="Spinning"
            small
            v-if="loading"
          ></b-spinner>
        </b-button>
      </div>
    </div>
    <ModalPublistEvent
      :textModal="textModal"
      @updatePreviewOrPublish="updatePreviewOrPublish"
    />
    <ModalAlertText :textModal="textModal" :arrModal="arrStatus" />
  </div>
</template>

<script>
import DetailTab from "./component/detailTab/index.vue";
import TicketZoneTab from "./component/ticketZoneTab/index.vue";
import BannerTab from "./component/bannerTab/index.vue";
import AdditionalDataTab from "./component/additionalDataTab/index.vue";
import CustomerTab from "./component/customerTab/index.vue";
import ScheduleTab from "./component/scheduleTab/index.vue";
import ModalPublistEvent from "../../eventsetting/eventsettingdetail/component/modal/ModalPublistEvent.vue";
import { required, requiredIf } from "vuelidate/lib/validators";

import ModalAlertText from "@/components/modal/ModalAlertText.vue";

export default {
  name: "ServiceBranchDetailPage",
  components: {
    DetailTab,
    TicketZoneTab,
    BannerTab,
    AdditionalDataTab,
    CustomerTab,
    ScheduleTab,
    ModalPublistEvent,
    ModalAlertText,
  },
  data() {
    return {
      errTab: [
        { id: 1, error: false },
        { id: 2, error: false },
        { id: 3, error: false },
        { id: 4, error: false },
        { id: 5, error: false },
        { id: 6, error: false },
      ],
      arrStatus: [],
      textModal: "",
      selectedLang: 2,
      listLang: [],
      tabIndex: null,
      loading: false,
      form: {
        event: {
          eventName: [
            {
              id: 0,
              languageId: 2,
              name: "",
            },
            {
              id: 0,
              languageId: 1,
              name: "",
            },
          ],
          description: [
            {
              id: 0,
              languageId: 2,
              description: "",
            },
            {
              id: 0,
              languageId: 1,
              description: "",
            },
          ],
        },
      },
      additionalDataEvent: [],
      descriptionEvent: [],
      shortDescriptionEvent: [],
    };
  },
  validations() {
    return {
      form: {
        additionalData: {
          $each: {
            additionalLanguageData: {
              $each: {
                topic: {
                  required: requiredIf((x) => {
                    return x.languageId == 2 && x.topic == "";
                  }),
                },
                detail: {
                  required: requiredIf((x) => {
                    return x.languageId == 2 && x.detail == "";
                  }),
                },
              },
            },
          },
        },
        event: {
          venueId: { required },
          eventName: {
            $each: {
              name: {
                required: requiredIf((event) => {
                  return event.languageId === 2 && this.selectedLang === 2;
                }),
              },
            },
          },
          description: {
            $each: {
              description: {
                required: requiredIf((des) => {
                  return (
                    des.languageId === 2 &&
                    this.selectedLang === 2 &&
                    !this.form.event.useDescriptionTemplate
                  );
                }),
              },
            },
          },
          shortDescription: {
            $each: {
              shortDescription: {
                required: requiredIf((short) => {
                  return (
                    short.languageId === 2 &&
                    this.selectedLang === 2 &&
                    !this.form.event.useDescriptionTemplate
                  );
                }),
              },
            },
          },
          // remark: {
          //   $each: {
          //     remark: {
          //       required: requiredIf((re) => {
          //         return re.languageId === 2 && this.selectedLang === 2;
          //       }),
          //     },
          //   },
          // },
        },
      },
    };
  },
  mounted() {
    this.getLanguageOption();
    this.getEventData();
  },
  watch: {
    "form.event.useEventInfoTemplate"(val) {
      if (val === true) {
        this.getAdditionalTemplate();
      } else {
        this.form.additionalData = this.additionalDataEvent;
      }
    },
    "form.event.useDescriptionTemplate"(val) {
      if (val === true) {
        this.getDescriptionTemplate();
      } else {
        this.form.event.shortDescription = this.shortDescriptionEvent;
        this.form.event.description = this.descriptionEvent;
      }
    },
  },
  computed: {
    language() {
      return this.tabIndex !== null
        ? this.listLang.find((x) => x.languageId === this.selectedLang)
        : {};
    },
    indexLanguage() {
      return this.tabIndex !== null
        ? this.listLang.findIndex((x) => x.languageId === 2)
        : 0;
    },
  },
  methods: {
    async getEventData() {
      this.$v.form.$reset();
      this.loading = true;

      const respone = await this.$services.servicebranch.getEventData(
        this.$route.params.id
      );

      if (respone.result === 1) {
        this.form = respone.detail;
        if (respone.detail.event.useEventInfoTemplate === true) {
          this.getAdditionalTemplate();
        } else {
          this.additionalDataEvent = respone.detail.additionalData;
          this.form.additionalData = this.additionalDataEvent;
        }

        const { description, shortDescription } = respone.detail.event;
        this.descriptionEvent = description;
        this.shortDescriptionEvent = shortDescription;
        if (respone.detail.event.useDescriptionTemplate === true) {
          this.getDescriptionTemplate();
        } else {
          this.form.event.description = this.descriptionEvent;
          this.form.event.shortDescription = this.shortDescriptionEvent;
        }
      }

      this.loading = false;
      this.tabIndex = 0;
    },
    async getDescriptionTemplate() {
      const respone = await this.$services.eventgroup.getDescriptionTemplate(
        this.$route.params.eventGroupId
      );
      if (respone.result === 1) {
        this.form.event.description = respone.detail.description;
        this.form.event.shortDescription = respone.detail.shortDescription;
      }
    },
    async getAdditionalTemplate() {
      const respone = await this.$services.eventgroup.getAdditionalTemplate(
        this.$route.params.eventGroupId
      );

      if (respone.result === 1) {
        this.form.additionalData = respone.detail;
      }
    },
    async onSaveEvent() {
      this.$v.form.$touch();

      if (this.$v.form.$error) return;
      this.adapterForm();
      this.loading = true;
      this.$services.servicebranch
        .saveServiceBranch(this.$route.params.id, this.form)
        .then((res) => {
          if (res.result === 1 && this.form.event.previewOrPublish === 0) {
            this.openModalPublistEvent();
          } else {
            this.openModalAlertText(res.detail);
          }
        })
        .catch((err) => {
          this.openModalAlertText("something went wrong");
        })
        .finally(() => {
          this.loading = false;
          this.getEventData();
        });
    },
    adapterForm() {
      if (this.form.additionalData.length > 0) {
        this.form.additionalData.forEach((e) => {
          if (typeof e.eventInfoId === "string") {
            e.eventInfoId = 0;
          }
        });
      }
      this.form.event.endTimeDisplay =
        this.form.event.endTimeDisplay === ""
          ? null
          : this.form.event.endTimeDisplay;

      this.form.event.startTimeDisplay =
        this.form.event.startTimeDisplay === ""
          ? null
          : this.form.event.startTimeDisplay;
    },
    async getLanguageOption() {
      const getData = await this.$services.master.getLanguageOption();

      if (getData.result == 1) {
        this.listLang = getData.detail;
        this.selectedLang = this.listLang[0].languageId;
      }
    },
    changeLanguage(v) {
      this.selectedLang = v.languageId;
    },

    handleBack() {
      this.$router.back();
    },
    openModalAlertText(text, dataArr) {
      if (!dataArr) {
        this.arrStatus = [];
      }
      this.textModal = text;
      this.$bvModal.show("modal-alert-text");
    },
    openModalPublistEvent() {
      this.$bvModal.show("modal-publish-event");
    },

    async updatePreviewOrPublish(val) {
      this.arrStatus = [];

      const respone = await this.$services.servicebranch.updatePreviewOrPublish(
        this.$route.params.id,
        val
      );

      if (respone.result == 1) {
        if (respone.detail.isAllReady == 0) {
          respone.detail.tab = respone.detail.tab.filter(
            (x) => x.error == true
          );

          this.arrStatus = respone.detail.tab;

          respone.detail.tab.forEach((e) => {
            this.errTab.forEach((x) => {
              if (e.id === x.id) {
                x.error = e.error;
              }
            });
          });
        }
      }
      this.openModalAlertText(respone.detail.message, "showarr");

      this.getEventData();
    },
  },
};
</script>

<style lang="scss">
.button-save {
  background-color: var(--secondary-color);
}
.item {
  color: var(--secondary-color);
}
.header-eventgroup {
  display: flex;
  justify-content: space-between;
}
.nav-link.active {
  color: var(--secondary-color) !important;
  border-bottom: 4px solid var(--secondary-color) !important;
}

.nav-link:hover {
  border-color: transparent transparent var(--secondary-color) transparent !important;
}
.footer-event-setting {
  display: flex;
  justify-content: space-between;
  padding-top: 10px;
}

// ! override
.btn-filter {
  width: 100%;
  max-width: 150px;

  &.cancel {
    border: none;
    background: #606060;
    color: white !important;
  }

  &.save {
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--secondary-color);
    border: none;
    color: white !important;
  }
  &.save:hover {
    background: var(--secondary-color);

    cursor: pointer;
  }
  &.cancel:hover {
    background: #606060;
    color: white !important;

    cursor: pointer;
  }
}
</style>
